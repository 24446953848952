<template>
  <div>
    <h6 v-if="titulo" class="subtitulo mb-3">{{ titulo }}</h6>
    <div class="usp-resultados" :class="[destacar,espacar]">
      <ul class="list-group" v-if="lista.length != 0">
        <li class="list-group-item list-group-item-action" v-for="item in lista" :key="item.id">
            <div class="linha">
                 <slot :item="item">
            </slot>
            </div>
        </li>
      </ul>
      <div class="alert alert-light mt-3"  v-if="lista.length == 0 && exibirMensagem">
        <p>Nenhum item foi adicionado.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    destaque: {
      type: Boolean,
    },
    espacamento: {
      type: Boolean,
    },
    exibirMensagem: {
      type: Boolean,
    },
    titulo: {
      type: String,
      required: false,
    },
    lista: {
      type: Array,
    },
  },
  computed: {
    destacar() {
      return this.destaque && this.lista.length>0 ? "destaque" : "";
    },
    espacar() {
      return this.espacamento ? "espacamento" : "";
    },
  },
};
</script>
<style scoped>
.list-group-item {
  padding: .5rem 1rem !important;
}
</style>