<template>
  <section>
    <div class="container-fluid">
      <!-- Breadcrumb -->
      <nav
        style="
          --bs-breadcrumb-divider: url(
            &#34;data:image/svg + xml,
            %3Csvgxmlns='http://www.w3.org/2000/svg'width='8'height='8'%3E%3Cpathd='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z'fill='currentColor'/%3E%3C/svg%3E&#34;
          );
        "
        aria-label="breadcrumb"
      >
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'servicos' }" tag="a">
              <i class="fas fa-home"></i> Home
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Assina:Inicio' }" tag="a">
              USP Assina
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Processando Assinatura
          </li>
        </ol>
      </nav>
      
      <uspCartao titulo="Processando Assinatura" :fechavel="false">
        <template slot="corpo">
          <p>Aguarde enquanto o sistema atualiza as informações e quando estiver terminado você será redirecionado para o lote em questão.</p>
          <p>Ou você pode ir trabalhando com outras atividades e depois voltar para verificar a situação do lote.</p>
        </template>
      </uspCartao>
    </div>
  </section>
</template>

<script>
import LoteDocumentoServico from '../dominio/loteDocumento/LoteDocumentoServico';

const api = LoteDocumentoServico.build({});

export default {
  name: 'AssinaRetornoEmbedded',

  props: {
    codlotdocasi: {
      type: String,
      default: '',
    },
    event: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      continua: 'S',
      timer: ''
    }
  },

  mounted() {
    const self = this;
    this.timer = setTimeout(
      function verificar() {
        if (self.continua == 'S') {
          api.verificarAtualizacao({
              codlotdocasi: self.codlotdocasi,
              event: self.event
            })
            .then((resp) => {
              if (resp.atualizado == 'S') {
                self.$router.push({ name: 'Assina:Lote', params: { codlotdocasi: self.codlotdocasi }});
              } else {
                self.timer = setTimeout(verificar, 5000);
              }
            })
            .catch(erro => {
              self.erro = (erro || "").mensagem || erro;
            });
        }
      },
      5000
    );
  },

  beforeDestroy() {
    this.continua = 'N';
    clearTimeout(this.timer);
  }
}
</script>