<template>
  <section>
    <div class="container-fluid">
      <uspAlerta 
        v-show="!!erro"
        variante="perigo"
        fechavel
      >
        {{ erro }}
      </uspAlerta>
      <uspCartao 
        titulo="Assinaturas - Listar" 
        class="container-fluid"
      >
        <template #corpo>
          <router-link
            v-if="['1','2'].indexOf(numnivseg) > -1"
            :to="{ name: 'Assina:Novo' }"
            class="btn btn-sm btn-primary ml-md-2 col-md-2 col-11 ml-3"
          >
            <em class="fa fa-plus" />
            Novo documento
          </router-link>
          <div class="form-check form-switch d-md-inline float-md-right pt-md-0 d-flex justify-content-center pt-1">
            <div>
              <input
                id="checkminhapendencia"
                v-model="filtroMinhapendencia"
                type="checkbox"
                class="form-check-input"
                @change="filtrar"
              >
            </div>
            <label 
              class="form-check-label" 
              for="checkminhapendencia"
            >
              Pendentes para eu assinar
            </label>
          </div>

          <!-- Filtros -->
          <div class="filtros mb-3 mt-3">
            <form>
              <div class="">
                <input
                  v-model="filtroTitulo"
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Filtrar por título"
                >
              </div>
              <div class="">
                <select
                  v-model="filtroPapel" 
                  class="form-control form-control-sm"
                  @change="filtrar"
                >
                  <option 
                    value="" 
                    selected
                  >
                    Filtrar por perfil...
                  </option>
                  <option
                    v-for="(value, key) in papeis"
                    :key="key"
                    :value="key"
                  >
                    {{ value }}
                  </option>
                </select>
              </div>
              <div class="">
                <select
                  v-model="filtroSituacao"
                  class="form-control form-control-sm"
                  @change="filtrar"
                >
                  <option 
                    value="" 
                    selected
                  >
                    Filtrar por situação...
                  </option>
                  <option
                    v-for="(value, key) in situacoes"
                    :key="key"
                    :value="key"
                  >
                    {{ value }}
                  </option>
                </select>
              </div>
              <div class="">
                <input
                  v-model="filtroData"
                  class="form-control form-control-sm"
                  type="date"
                  @change="filtrar"
                >
              </div>
                <!--<div class="">
                  <a href="#" class="btn btn-block btn-sm btn-outline-primary" @click.prevent="filtrar"
                    ><i class="fas fa-filter"></i> Filtrar
                  </a>
                </div>-->
            </form>
          </div>
          
        
          <!-- Lista de resultados 
          <AssinaResultados
            :lista="documentos"
            titulo=""
            destaque
          >
            <template slot-scope="conteudo">
              <div class="conteudo justify-content-between">
                <span class="titulo"> {{ conteudo.item.titlotdocasi }}</span>
                <span class="subtitulo"
                  >{{ papeis[conteudo.item.tipptpasidoc] }}
                </span>
                <span class="subtitulo"
                  >{{ situacoes[conteudo.item.sitlotdocasi] }}
                </span>
                <span class="subtitulo">{{ conteudo.item.dtacad }}</span>
              </div>
              <div class="acoes">
                <router-link
                  :to="{
                    name: 'Assina:Lote',
                    params: { codlotdocasi: conteudo.item.codlotdocasi },
                  }"
                  tag="button"
                  class="btn btn-sm btn-info"
                >
                  <i class="fas fa-eye"></i>
                  Visualizar
                </router-link>
              </div>
            </template>
          </AssinaResultados>-->
        </template>
      </uspCartao>
      
      <uspCartao titulo="Lista de documentos">
        <template #corpo>
          <uspSkeleton v-if="!carregado">
            <div 
              v-for="index in 10" 
              :key="index"
            >
              <div class="w-40" />
              <div class="w-30" />
              <div class="w-15" />
              <div class="w-15" />
            </div>
          </uspSkeleton>

          <uspTabela
            v-if="documentos.length > 0"
            :dados="documentos"
            paginacao
            :por-pagina="15"
            :campos="campos"
            class="table-responsive"
          >
            <template #acoes="{ data }">
              <router-link
                :to="{
                  name: 'Assina:Lote',
                  params: { codlotdocasi: data.codlotdocasi },
                }"
                class="btn btn-sm btn-info"
              >
                <em class="fas fa-eye" />
                Visualizar
              </router-link>
            </template>
          </uspTabela>
        </template>
      </uspCartao>
    </div>
  </section>
</template>

<script>
import LoteDocumentoServico from "../dominio/loteDocumento/LoteDocumentoServico";

const api = LoteDocumentoServico.build({});

export default {
  name: "AssinaInicio",

  created() {
    this.papeis = this.$uassConst("papeis");
    this.situacoes = this.$uassConst("situacoes");
  },

  mounted() {
    const self = this;
    this.numnivseg = this.$route.params.numnivseg;

    if (typeof(sessionStorage) !== "undefined") {
      const uspassinaStorage = JSON.parse(sessionStorage.getItem("uspassinaStorage") || "{}").filtros || {};
      self.filtroTitulo = uspassinaStorage.filtroTitulo || self.filtroTitulo;
      self.filtroPapel = uspassinaStorage.filtroPapel || self.filtroPapel;
      self.filtroSituacao = uspassinaStorage.filtroSituacao || self.filtroSituacao;
      self.filtroData = uspassinaStorage.filtroData || self.filtroData;
      self.filtroMinhapendencia =
        (typeof uspassinaStorage.filtroMinhapendencia === "boolean" || ["true","false"].includes(uspassinaStorage.filtroMinhapendencia)) ?
            uspassinaStorage.filtroMinhapendencia
            :
            self.filtroMinhapendencia;
    }

    self.atualizarListaOriginal().then(() => {
      self.filtrar();
      self.carregado = true;
    });
  },

  data() {
    return {
      numnivseg: "",
      documentos: [],
      listaOriginal: [],
      ultimaAtualizacao: new Date(),
      carregado: false,
      erro: "",
      filtroTitulo: "",
      filtroPapel: "",
      filtroSituacao: "",
      filtroData: "",
      filtroMinhapendencia: true,
      campos: [
              {
                campo: 'titlotdocasi',
                nome: 'Título',
                ordenavel: true,
              },
              {
                campo: 'tipptpasidoc',
                nome: 'Papel',
                ordenavel: false,
                formato: (value) => {
                  return this.papeis[value];
                },
              },
              {
                campo: 'sitlotdocasi',
                nome: 'Situação',
                ordenavel: false,
                formato: (value) => {
                  return this.situacoes[value];
                },
              },
              {
                campo: 'dtacad',
                nome: 'Data do cadastro',
                ordenavel: true,
              },
              {
                campo: 'acoes',
                nome: 'Ações',
              },
            ]
    };
  },

  methods: {
    atualizarListaOriginal() {
      const self = this;
      self.documentos = [];
      return api.listar().then((resp) => {
          self.ultimaAtualizacao = new Date();
          self.listaOriginal = resp;
          return new Promise((resolve) => {resolve();});
        })
        .catch((erro) => {
          self.listaOriginal = [];
          self.erro = ((erro || "").mensagem || erro);
        });
    },
    
    filtrar(param, recursao) {
      const self = this;
      const fTitulo = new RegExp(self.filtroTitulo, "i");
      
      if (typeof(sessionStorage) !== "undefined") {
        const uspassinaStorage = JSON.parse(sessionStorage.getItem("uspassinaStorage") || "{}");
        uspassinaStorage.filtros = {
          "filtroTitulo" : self.filtroTitulo,
          "filtroPapel" : self.filtroPapel,
          "filtroSituacao" : self.filtroSituacao,
          "filtroData" : self.filtroData,
          "filtroMinhapendencia" : self.filtroMinhapendencia
        };
        sessionStorage.setItem("uspassinaStorage", JSON.stringify(uspassinaStorage));
      }

      if (!recursao && (new Date() - self.ultimaAtualizacao) > 30000) {
        self.atualizarListaOriginal().then(self.filtrar.bind(self, param, true));
        return;
      }
      self.documentos = self.listaOriginal.filter((elem) => {
        const temTitulo = self.filtroTitulo
            ? (elem.titlotdocasi || "").match(fTitulo)
            : true,
          temPapel = self.filtroPapel
            ? elem.tipptpasidoc == self.filtroPapel
            : true,
          temSituacao = self.filtroSituacao
            ? elem.sitlotdocasi == self.filtroSituacao
            : true,
          temData = self.filtroData
            ? self.filtroData.substring(8, 10) +
                "/" +
                self.filtroData.substring(5, 7) +
                "/" +
                self.filtroData.substring(0, 4) ==
              elem.dtacad
            : true,
          temMinhapendencia = self.filtroMinhapendencia
            ? elem.minhapendencia == 'S'
            : true;
        return temTitulo && temPapel && temSituacao && temData && temMinhapendencia;
      });
    },
  },

  watch: {
    filtroTitulo(newValue, oldValue) {
      if (newValue != oldValue) {
        clearTimeout(this.settimeoutObject);
        this.settimeoutObject = setTimeout(this.filtrar, 2000);
      }
    },
  },
};
</script>
<style lang="scss">
@import "./src/componentes/estilos/temas.scss";

:not(.input-group-prepend) input.form-control {
  border-radius: 1.8rem !important;
}
/*.filtros {
  margin-left: 0 !important;
}
.conteudo {
  flex-basis: 80% !important;
}
.titulo {
  flex-basis: 70%;
}
.subtitulo {
  flex-basis: 10%;
}*/

.usp-resultados {
  margin-bottom: 0.5rem;

  ul.list-group {
    border-radius: 1rem;

    .list-group-item {
      border-width: 1px;
      background-color: transparent;

      & + .list-group-item {
        border-top-width: 0px;
      }

      &.list-group-item-action:hover {
        background-color: #f0f0f0;
        div {
          background: #f0f0f0;
        }
      }

      .linha {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        & + * {
          display: flex;
          align-items: center;
        }
        .conteudo {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-basis: 70%;
          > img {
            border-radius: 1rem;
            margin-right: 4rem;
            //@extend .;
            height: 50px;
            width: 50px;
          }
          .multiplas-linhas {
            display: flex;
            flex-direction: column;
          }
          .titulo {
            font-weight: 500;
            color: #1094ab;
          }
          .subtitulo {
            font-size: 0.95rem;
            color: #444;
            flex-basis: 20%;
          }
        }
        .acoes {
          .btn + .btn {
            margin-left: 0.5rem;
          }
        }
      }

      .acoes-do-item {
        border: 0px solid #f8f9fa;
        background: #fff;
        border-radius: 1rem;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        box-shadow: 0 5px 20px 0 rgba(69, 67, 96, 0.05);
        margin-bottom: 1rem;
      }
    }
  }

  &.espacamento {
    .list-group-item {
      border-radius: 1rem;
      border-color: #f7f7f7;
    }
    .list-group-item + .list-group-item {
      margin-top: 1rem;
      border-top-width: 2px;
    }
  }

  &.destaque {
    background: #f8f9fa;
    padding: 0.8rem;
    border-radius: 0.8rem;
    li,
    div {
      background: #fff;
    }
  }
}

/* Por temas */
@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    .usp-resultados {
      ul.list-group {
        background: map-get($tema, "usp-subelemento-cor-de-fundo");

        .list-group-item {
          &.list-group-item-action:hover {
            background-color: map-get(
              $tema,
              "usp-subelemento-cor-de-fundo-desabilitado"
            );
            div {
              background: map-get(
                $tema,
                "usp-subelemento-cor-de-fundo-desabilitado"
              );
            }
          }

          .linha {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            & + * {
              display: flex;
              align-items: center;
            }
            .conteudo {
              .titulo {
                color: #1094ab;
              }
              .subtitulo {
                color: map-get($tema, "usp-elemento-cor-do-texto");
              }
            }
          }

          .acoes-do-item {
            border: 0px solid #f8f9fa;
            background: #fff;
          }
        }
      }

      &.espacamento {
        .list-group-item {
          border-color: #f7f7f7;
        }
      }

      &.destaque {
        background: map-get($tema, "usp-tela-cor-de-fundo");
        li,
        div {
          background: transparent;
        }
      }
    }
  }
}
</style>

<style scoped>
  .float-end {
    float: right
  }
</style>