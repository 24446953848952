<template>
  <section>
    <div class="container-fluid">
      
      <uspCartao
        titulo="Processo digital atrelado"
        v-if="dadosHash.numpro"
      >
        <template slot="corpo">
          <div class="usp-resultados">
            <ul class="list-group">
              <li class="list-group-item">
                <div class="linha">
                  <div class="conteudo d-flex flex-row">
                    <div class="info d-flex flex-column">
                      <span>Número do processo: {{ dadosHash.numpro }}</span>
                    </div>
                  </div>
                  <div class="acoes">
                    <a
                      role="button"
                      :href="baseUrl + '/wsprocesso/viewer/viewer.html?file=/proxy/wsprocesso/api/processo/' + dadosHash.numpro + '/pdf?modoView=inline'"
                      target="_blank"
                      class="btn btn-sm btn-info"
                    >
                      <i class="fas fa-eye"></i> Visualizar
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </uspCartao>
      
      <uspCartao titulo="Assinar documento">
        <template slot="corpo">
          <p>Caso o documento não seja exibido abaixo, <a style="text-decoration: underline;" :href="docFile" download="documento.pdf">clique aqui</a> para fazer download.</p>
          <object
            :data="docFile"
            v-bind="contentTypeAttr"
            style="min-height: 500px;"
            class="w-100 h-100"
          >
            <p>Não foi possível exibir o documento. Utilize o botão abaixo para fazer download:</p>
            <p class="text-center"><a
              :href="docFile"
              download="documento.pdf"
              class="btn btn-sm btn-primary"
            >
              Download do documento
            </a></p>
          </object>
          <div class="d-flex justify-content-center mt-3">
            <button type="button" class="btn btn-primary" :disabled="carregando" @click.prevent="assinar">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="carregando"></span>
                Assinar documento
            </button>
          </div>
        </template>
      </uspCartao>
    </div>
  </section>
</template>

<script>
import LoteDocumentoServico from '../dominio/loteDocumento/LoteDocumentoServico.js';
import eventBus from '@/eventBus';

const api = LoteDocumentoServico.build({});

export default {
  name: 'AssinaAssinarDocumento',
  
  props: {
    dadosHash: {}
  },
  
  data() {
    return {
      docFile: '',
      carregando: false,
      erro: '',
      baseUrl: window.location.origin,
      contentTypeAttr: /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? {} : {type: "application/pdf"}
    };
  },

  created() {
    const self = this;
    api.obterDocumento(self.dadosHash)
      .then((resp) => {
        self.docFile = URL.createObjectURL(resp);
      })
      .catch(erro => self.erro = (erro || "").mensagem || erro)
      .finally(() => {
        self.carregando = false;
      });
  },

  methods: {
    assinar() {
      const self = this;
      self.carregando = true;
      api.assinarDocumento(self.dadosHash)
        .then(() => {
          self.$router.push({ name: 'Assina:Inicio' });
        })
        .catch(erro => self.erro = (erro || "").mensagem || erro)
        .finally(() => {
          self.carregando = false;
        });
    }
  },

  watch: {
    erro(newValue) {
      if (newValue) {
        eventBus.mostrarMensagemToast({
              titulo: "Erro ao Assinar!",
              mensagem: this.erro,
              variante: 'danger'
            })
        this.erro = "";
      }
    },
  },
  
  destroyed: function() {
    URL.revokeObjectURL(this.docFile);
    this.docFile = "";
  }
};
</script>


<style>
</style>