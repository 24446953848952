<template>
  <section>
    <div class="container-fluid">
      <!-- Pacote de documentos -->
      <uspCartao titulo="Lote de documentos">
        <template slot="corpo">
          <uspAlerta variante="claro" v-show="sitlotdocasi == 'REJ'">
            <strong>Lote rejeitado</strong>&nbsp;(Motivo: {{ motrejlotdoc }})
          </uspAlerta>

          <p v-if="!codlotdocasi">
            Informe abaixo as propriedades deste novo lote de documentos.
          </p>

          <form @submit.prevent="salvarLote">
            <div class="form-row">
              <div class="form-group col-xl-9">
                <label 
                  for="titlotdocasi" 
                  class="control-label"
                >
                  Título
                </label>
                <input
                  v-if="editavel"
                  id="titlotdocasi"
                  v-model="titlotdocasi"
                  type="text"
                  class="form-control"
                  maxlength="255"
                >
                <span 
                  v-else
                  class="form-control"
                >
                  {{ titlotdocasi }}
                </span>
              </div>
              <div class="form-group col-xl-3">
                <label 
                  for="dtalim" 
                  class="control-label"
                >
                  Data limite para assinatura
                </label>
                <input
                  v-if="editavel"
                  id="dtalim"
                  v-model="dtalimaucdgt"
                  v-maska
                  data-maska="##/##/####"
                  class="form-control"
                >
                <span 
                  v-else
                  class="form-control"
                >
                  {{ dtalimaucdgt }}
                </span>
              </div>
            </div>
            <!--
              <div class="form-group col-xs-6 col-md-6">
                <label for="name" class="control-label">Unidade acadêmica</label>
                <input
                  type="text"
                  value=""
                  class="form-control"
                  id="name"
                />
              </div>
              <div class="form-group col-xs-4 col-md-4">
                <label for="name" class="control-label">Tipo de envelope</label>
                <input
                  type="text"
                  value=""
                  class="form-control"
                  id="name"
                />
              </div>
              <div class="form-group col-xs-12 col-md-12">
                <label for="name" class="control-label">Descrição</label>
                <textarea
                  name="text"
                  id="text"
                  class="form-control"
                  rows="4"
                ></textarea>
              </div>
              -->
            <!--<div class="w-100 d-none d-md-block"></div>-->
            <div class="form-row">
              <div class="form-group col-xl-4">
                <label for="tipasilotdoc" class="control-label"
                  >Tipo de assinatura do lote de documentos
                  <a
                    href="https://uspdigital.usp.br/comumwebdev/arquivos/portal/Portaria_GR_assinatura.pdf"
                    target="_blank"
                    title="Qual tipo de assinatura devo utilizar?"
                    ><i class="fas fa-question-circle"></i></a
                ></label>
                <select
                  id="tipasilotdoc"
                  v-model="tipasilotdoc"
                  v-if="!codlotdocasi"
                  class="form-control"
                >
                  <option value="">- Selecione -</option>
                  <option
                    v-for="(value, key) in tiposAssinatura"
                    :value="key"
                    v-bind:key="key"
                  >
                    {{ value }}
                  </option>
                </select>
                <span class="form-control" v-else>{{
                  tiposAssinatura[tipasilotdoc]
                }}</span>
              </div>
              <div class="form-group col-xl-4" v-if="tipasilotdoc == 'U' || tipasilotdoc == 'I' || tipasilotdoc == 'A'">
                <label for="emadttntfasi" class="control-label">
                  Quando finalizado, enviar email para:
                  <a
                    href=""
                    @click.prevent=""
                    title="Para enviar para vários destinatários, utilize ponto-e-vírgula para separar os e-mails. Exemplo: exemplo1@usp.br;exemplo2@usp.br;exemplo3@usp.br"
                  >
                    <i class="fas fa-question-circle"></i>
                  </a>
                </label
                >
                <input
                  type="text"
                  class="form-control"
                  id="emadttntfasi"
                  v-model="emadttntfasi"
                  v-if="editavel"
                />
                <span class="form-control" v-else>{{ emadttntfasi }}</span>
              </div>
              <div
                v-if="tipasilotdoc == 'U'" 
                class="form-group col-xl-4"
              >
                <label
                  for="stalincmvasi"
                  class="control-label"
                >
                  Certificado da(s) assinatura(s) em:
                </label>
                <select
                  id="stalincmvasi"
                  v-model="stalincmvasi"
                  class="form-control"
                  v-if="editavel"
                >
                  <option value="">Português</option>
                  <option value="I">Inglês</option>
                </select>
                <span class="form-control" v-else>{{
                  {"":"Português", "I":"Inglês"}[stalincmvasi]
                }}</span>
              </div>
            </div>
            <uspAlerta variante="aviso" v-show="tipasilotdoc == 'A'">
              Para o tipo de assinatura da Acrobat Sign, a assinatura visual &eacute; posicionada ao final do documento, no primeiro espa&ccedil;o em branco que existir. Dessa forma, por favor, formate seu documento considerando esta caracter&iacute;stica da ferramenta.<br>
              A validade do documento pode ser verificada abrindo-se o PDF assinado utilizando um programa leitor de PDFs (como o Acrobat Reader), no painel de assinaturas.
            </uspAlerta>
            <div
              class="acoes d-flex justify-content-end"
              v-if="sitlotdocasi != 'ASS' && sitlotdocasi != 'REJ'"
            >
              <button
                type="button"
                @click.prevent="
                  motrejlotdoc = '';
                  $refs.modal.openModal();
                "
                class="btn btn-danger mr-2"
                :disabled="carregando"
                v-if="(tipptpasidoc == 'G' || tipptpasidoc == 'A') && (sitlotdocasi == 'CRI' || sitlotdocasi == 'ENV')"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  v-if="carregando"
                ></span>
                <i class="fas fa-trash" v-else></i>
                {{ sitlotdocasi == "ENV" ? "Rejeitar" : "Excluir" }}
              </button>

              <button
                type="submit"
                class="btn btn-success"
                :disabled="carregando || !loteModificado"
                v-if="editavel"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  v-if="carregando"
                ></span>
                <i class="fas fa-save" v-else></i>
                Salvar
              </button>
            </div>
          </form>

          <uspAlerta ref="alertaSalvar" variante="perigo" @fechar="eventoFecharUspAlerta($refs.alertaEnviar, 'erro1')" v-show="!!erro1" fechavel>
            {{ erro1 }}
          </uspAlerta>

        </template>
      </uspCartao>

      <!-- Participantes -->
      <AssinaPessoas
        titulo="Participantes"
        :codlotdocasi="codlotdocasi"
        :tipasilotdoc="tipasilotdoc"
        :modoEdicao="editavel"
        :sitlotdocasi="sitlotdocasi"
        @uspPessoaAtualizouLista="atualizouListaParticipante"
        v-if="!!codlotdocasi"
      ></AssinaPessoas>

      <!-- Arquivos -->
      <AssinaArquivos
        :codlotdocasi="codlotdocasi"
        :tipasilotdoc="tipasilotdoc"
        :sitlotdocasi="sitlotdocasi"
        v-bind:modoEdicao="editavel"
        @AssinaArquivosAtualizouLista="atualizouListaDocumento"
        v-if="!!codlotdocasi"
      />

      <uspCartao
        titulo="Processo digital atrelado"
        v-if="numpro"
      >
        <template slot="corpo">
          <div class="upload usp-resultados destaque">
            <ul class="list-group">
              <li class="list-group-item">
                <div class="linha">
                  <div class="conteudo d-flex flex-row">
                    <div class="info d-flex flex-column">
                      <span>Número do processo: {{ numpro }}</span>
                    </div>
                  </div>
                  <div class="acoes">
                    <a
                        role="button"
                        :href="baseUrl + '/wsprocesso/viewer/viewer.html?file=/proxy/wsprocesso/api/processo/' + numpro + '/pdf?modoView=inline'"
                        target="_blank"
                        class="btn btn-sm btn-info"
                    >
                      <i class="fas fa-eye"></i> Visualizar
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </uspCartao>
      
      <uspCartao
        titulo="Concluir"
        v-if="temParticipante && temDocumento && !modificado && editavel"
      >
        <template slot="corpo">
          <p>
            Se você já efetuou todos os procedimentos, clique no botão "Enviar"
            para enviar os documentos para assinatura.
          </p>
        </template>
        <template slot="rodape">
          <div class="acoes">
            <button
              class="btn btn-success"
              :disabled="
                carregando || !(temParticipante && temDocumento && !modificado)
              "
              @click.prevent="enviar"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-if="carregando"
              ></span>
              Enviar documentos para assinatura
            </button>
          </div>

          <uspAlerta ref="alertaEnviar" variante="perigo" @fechar="eventoFecharUspAlerta($refs.alertaEnviar, 'erro2')" v-show="!!erro2" fechavel>
            {{ erro2 }}
          </uspAlerta>

        </template>
      </uspCartao>

      <uspCartao
        titulo="Sincronizar"
        v-if="codlotdocasi && sitlotdocasi != 'CRI' && numnivseg == '1' && (tipasilotdoc == 'U' || ((tipasilotdoc == 'D' || tipasilotdoc == 'A') && sitlotdocasi == 'ENV'))"
      >
        <template slot="corpo">
          
          <p>
            Se ocorrer algum problema durante a assinatura de algum documento ou na sincronização dos dados com o fornecedor externo, utilizar o botão abaixo para tentar sincronizar agora.
          </p>

        </template>
        <template slot="rodape">
          <div class="acoes">
            <button
              type="button"
              @click.prevent="sincronizarDocusign"
              class="btn btn-danger mr-2"
              :disabled="carregando"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                v-if="carregando"
              ></span>
              <i class="fas fa-sync" v-else></i>
              Sincronizar dados
            </button>
          </div>
          
          <uspAlerta ref="alertaSinc" variante="perigo" @fechar="eventoFecharUspAlerta($refs.alertaSinc, 'erro2')" v-show="!!erro2" fechavel>
            {{ erro2 }}
          </uspAlerta>
          
        </template>
      </uspCartao>

      <uspModal
        :titulo="sitlotdocasi == 'ENV' ? 'Rejeitar lote' : 'Excluir lote'"
        ref="modal"
        @fechar="erro3 ? erro3 = '' : null"
      >
        <template slot="body">
          <p v-if="sitlotdocasi == 'ENV'">
            Ao rejeitar o lote, não será mais possível dar continuidade nas
            assinaturas dos documentos deste lote. Esta operação não poderá ser
            desfeita. Deseja continuar?
          </p>
          <p v-if="sitlotdocasi == 'CRI'">
            Ao excluir o lote, todos os dados deste lote serão perdidos. Esta
            operação não poderá ser desfeita. Deseja continuar?
          </p>
          <b-form-group
            :label="'Motivo (' + motrejlotdocCount + ' de 500 caracteres):'"
            v-if="sitlotdocasi == 'ENV'"
          >
            <b-form-textarea v-model="motrejlotdoc" maxlength="500">
            </b-form-textarea>
          </b-form-group>

          <uspAlerta ref="alertaModal" variante="perigo" @fechar="eventoFecharUspAlerta($refs.alertaModal, 'erro3')" v-show="!!erro3" fechavel>
            {{ erro3 }}
          </uspAlerta>
        </template>

        <div slot="footer">
          <button
            type="button"
            class="btn btn-outline-danger mr-1"
            @click.prevent="$refs.modal.closeModal()"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-success"
            data-dismiss="modal"
            :disabled="!motrejlotdocCount && sitlotdocasi == 'ENV'"
            @click.prevent="sitlotdocasi == 'ENV' ? rejeitar() : excluir()"
          >
            Confirmar
          </button>
        </div>
      </uspModal>
    </div>
  </section>
</template>

<script>
import { PortalLogger as Log } from "@/utils/";
import AssinaArquivos from "../components/AssinaArquivos";
import AssinaPessoas from "../components/AssinaPessoas";
import LoteDocumentoServico from "../dominio/loteDocumento/LoteDocumentoServico.js";
import uspModal from "@/componentes/components/uspModal";

import { BFormGroup, BFormTextarea } from "bootstrap-vue";

const api = LoteDocumentoServico.build({});

export default {
  name: "AssinaCriarPacote",

  components: {
    uspModal,
    AssinaPessoas,
    AssinaArquivos,
    "b-form-group": BFormGroup,
    "b-form-textarea": BFormTextarea,
  },

  data() {
    return {
      numnivseg: "",
      codlotdocasi: "",
      tipasilotdoc: "",
      stalincmvasi: "",
      sitlotdocasi: "",
      titlotdocasi: "",
      tipptpasidoc: "",
      dtalimaucdgt: "",
      emadttntfasi: "",
      motrejlotdoc: "",
      numpro: "",
      cursorPosition: 0,
      erro1: "",
      erro1TimeoutId: null,
      erro2: "",
      erro2TimeoutId: null,
      erro3: "",
      erro3TimeoutId: null,
      temParticipante: false,
      temDocumento: false,
      carregando: false,
      modificado: false,
      loteGravado: {},
      tiposAssinatura: {},
      baseUrl: window.location.origin
    };
  },

  created() {
    const self = this;
    this.reset();
    /*this.tiposAssinatura = {
      //D: "ASSINATURA ELETRÔNICA (Simples - Docusign)",
      I: "ASSINATURA DIGITAL (Qualificada - ImprensaOficial)",
      U: "USP - ID Digital (Avançado)",
    };*/
    api
        .listarTipoAssinatura()
        .then((tipos) => {
          for (let i in tipos) {
            self.tiposAssinatura[tipos[i].codigo] = tipos[i].descricao;
          }
          console.log(self.tiposAssinatura);
          self.$forceUpdate();
        })
        .catch((erro) => (self.erro1 = (erro || "").mensagem || erro));
  },

  mounted() {
    this.numnivseg = this.$route.params.numnivseg;
  },

  methods: {
    reset() {
      //const lote = this.$store.state.loteAtual || {};
      const lote = JSON.parse(sessionStorage.getItem("loteAtual")) || {};
      if (this.$route.params.codlotdocasi == lote.codlotdocasi) {
        this.loteGravado = lote;
        Object.keys(lote).forEach((key) => (this[key] = lote[key] || ""));
      }
    },
    validarData(data) {
      const parts = data.split('/').map((p) => parseInt(p, 10));
      parts[1] -= 1;
      const d = new Date(parts[2], parts[1], parts[0]);
      return d.getMonth() === parts[1] && d.getDate() === parts[0] && d.getFullYear() === parts[2];
    },
    salvarLote() {
      const self = this,
      redireciona = !this.codlotdocasi;
      if (self.dtalimaucdgt && !self.validarData(self.dtalimaucdgt)) {
        alert("Data limite para assinatura inválida! Por favor verifique.");
        return;
      }
      const bean = {
        codlotdocasi: self.codlotdocasi,
        tipasilotdoc: self.tipasilotdoc,
        titlotdocasi: self.titlotdocasi,
        dtalimaucdgt: self.dtalimaucdgt,
        emadttntfasi: self.emadttntfasi,
        stalincmvasi: self.stalincmvasi
      };
      self.carregando = true;
      api
        .salvar(bean)
        .then((resp) => {
          if (redireciona) {
            //self.$router.push('/assina/lote/' + resp.codlotdocasi);
            self.$router.push({
              name: "Assina:Lote",
              params: { codlotdocasi: resp.codlotdocasi },
            });
          }
        })
        .catch((erro) => (self.erro1 = (erro || "").mensagem || erro))
        .finally(() => (self.carregando = false));
    },

    atualizouListaParticipante(isListaOk) {
      Log.info("atualizouListaParticipante");
      this.temParticipante = isListaOk;
    },

    atualizouListaDocumento(isListaOk) {
      Log.info("atualizouListaDocumento");
      this.temDocumento = isListaOk;
    },

    excluir() {
      const self = this;
      self.$refs.modal.closeModal();
      self.carregando = true;
      api
        .excluir({
          codlotdocasi: self.codlotdocasi,
        })
        .then(() => {
          self.$router.push({ name: "Assina:Inicio" });
        })
        .catch((erro) => (self.erro1 = (erro || "").mensagem || erro))
        .finally(() => (self.carregando = false));
    },

    rejeitar() {
      const self = this;
      self.$refs.modal.closeModal();
      self.carregando = true;
      api
        .rejeitar({
          codlotdocasi: self.codlotdocasi,
          motrejlotdoc: self.motrejlotdoc,
        })
        .then(() => {
          self.$router.push({ name: "Assina:Inicio" });
        })
        .catch((erro) => (self.erro1 = (erro || "").mensagem || erro))
        .finally(() => (self.carregando = false));
    },

    enviar() {
      const self = this;
      self.carregando = true;
      api
        .enviar({
          codlotdocasi: self.codlotdocasi,
        })
        .then(() => {
          this.$router.push({ name: "Assina:Inicio" });
        })
        .catch((erro) => (self.erro2 = (erro || "").mensagem || erro))
        .finally(() => (self.carregando = false));
    },

    sincronizarDocusign() {
      const self = this;
      self.carregando = true;
      api
        .sincronizarDocusign({
          codlotdocasi: self.codlotdocasi,
        })
        .then(() => {
          this.$router.push({ name: "Assina:Inicio" });
        })
        .catch((erro) => (self.erro2 = (erro || "").mensagem || erro))
        .finally(() => (self.carregando = false));
    },

    eventoFecharUspAlerta(alertaRef, erroRef) {
      (alertaRef || {}).visivel = true;
      this[erroRef] = "";
    },

    handleInput(evt) {
      if (
        !this.deleteKey &&
        (evt.target.selectionStart == 3 || evt.target.selectionStart == 6)
      ) {
        this.cursorPosition = evt.target.selectionStart + 1;
      } else {
        this.cursorPosition = evt.target.selectionEnd;
      }
      this.deleteKey = false;
    },
  },

  destroyed: function() {
    Log.info("destroyed");
    //this.$store.commit('clear_loteAtual');
    sessionStorage.removeItem("loteAtual");
  },

  computed: {
    loteModificado: function() {
      const bean = {
        tipasilotdoc: this.tipasilotdoc,
        titlotdocasi: this.titlotdocasi,
        dtalimaucdgt: this.dtalimaucdgt,
        emadttntfasi: this.emadttntfasi,
        stalincmvasi: this.stalincmvasi
      };
      return [
        "tipasilotdoc",
        "titlotdocasi",
        "dtalimaucdgt",
        "emadttntfasi",
        "stalincmvasi"
      ].some((elem) => (this.loteGravado[elem] || "") != (bean[elem] || ""));
    },

    editavel: function() {
      return !this.sitlotdocasi || (this.sitlotdocasi == "CRI" && this.tipptpasidoc == "G");
    },

    motrejlotdocCount: function() {
      return (this.motrejlotdoc || "").length;
    },
  },

  watch: {
    erro1(newValue, oldValue) {
      const self = this;
      if (newValue) {
        clearTimeout(self.erro1TimeoutId);
        self.erro1TimeoutId = setTimeout(function() {
          self.erro1 = "";
        }, 60000);
      } else if (newValue != oldValue) {
        clearTimeout(self.erro1TimeoutId);
      }
    },
    erro2(newValue, oldValue) {
      const self = this;
      if (newValue) {
        clearTimeout(self.erro2TimeoutId);
        self.erro2TimeoutId = setTimeout(function() {
          self.erro2 = "";
        }, 60000);
      } else if (newValue != oldValue) {
        clearTimeout(self.erro2TimeoutId);
      }
    },
    erro3(newValue, oldValue) {
      const self = this;
      if (newValue) {
        clearTimeout(self.erro3TimeoutId);
        self.erro3TimeoutId = setTimeout(function() {
          self.erro3 = "";
        }, 60000);
      } else if (newValue != oldValue) {
        clearTimeout(self.erro3TimeoutId);
      }
    },
    tipasilotdoc(newValue) {
      if (newValue != "U") {
        this.stalincmvasi = "";
      }
    },
    $route(to, from) {
      Log.info(to);
      Log.info(from);
      this.reset();
    },
  },

  directives: {
    update(elem) {
      if (elem.selectionEnd !== elem.dataset.position) {
        elem.selectionEnd = elem.dataset.position;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./src/componentes/estilos/temas.scss";

.breadcrumb {
  background: none !important;
  font-size: 0.9rem;
  padding: 0rem !important;
  margin-bottom: 1.5rem;
  background: none;

  .breadcrumb-item {
    &.active {
      opacity: 0.6;
    }

    &:hover {
      opacity: 0.7;
      a {
        text-decoration: none;
      }
    }

    & + .breadcrumb-item::before {
      color: #fff;
      opacity: 0.6;
      content: ">";
    }
  }
}

@each $tema in $temas {
  $elemento: map-get($tema, "elemento");
  #{$elemento} {
    .breadcrumb {
      a {
        color: map-get($tema, "usp-cabecalho-cor-do-texto");
      }
      .breadcrumb-item {
        &.active {
          color: map-get($tema, "usp-cabecalho-cor-do-texto");
        }
        & + .breadcrumb-item::before {
          color: map-get($tema, "usp-cabecalho-cor-do-texto");
        }
      }
    }
  }
}
</style>
